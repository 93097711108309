import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
 

const Checkout = () => {
  const { uniqueId } = useParams();
  const [shippingInfo, setShippingInfo] = useState(null);
  const [distance, setDistance] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const googleApiKey = 'AIzaSyBSQ2eHtJbnjd_PdMEPbxbm5Nl_3P2-9hw'; // Replace with your API key

  useEffect(() => {
    const fetchShippingInfo = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch shipping info from your backend API
        const response = await axios.get(
          `https://api.shoppervista.com/api/Shippinginfo/search/tkid?Id=${uniqueId}`
        );

        if (response.data) {
          setShippingInfo(response.data);

          const { transportfrom, transportto } = response.data;

          // Fetch distance using Google Distance Matrix API
          const distanceResponse = await axios.get(`https://www.shoppervista.com:5000/api/distance`,
            {
              params: {
                origins: transportfrom,
                destinations: transportto,
                key: googleApiKey,
              },
            }
          );

          if (
            distanceResponse.data?.rows[0]?.elements[0]?.status === 'OK'
          ) {
            const distanceInKm = parseFloat(
              distanceResponse.data.rows[0].elements[0].distance.text.replace(/[^\d.]/g, '') // Extract numeric value
            );
          
            const distanceInMiles = (distanceInKm / 1.609).toFixed(2); // Convert km to miles
            const distanceInKmFormatted = `${distanceInKm.toFixed(2)} km`;
            const distanceInMilesFormatted = `${distanceInMiles} miles`;
          
            setDistance(`${distanceInKmFormatted} (${distanceInMilesFormatted})`);
          } else {
            setDistance('Distance calculation failed');
          }
        } else {
          setError('No data found for the given ID.');
        }
      } catch (err) {
        console.error('Error fetching data:', err.response?.data || err.message);
       // setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchShippingInfo();
  }, [uniqueId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  if (!shippingInfo) {
    return <div>No shipping information available.</div>;
  }

  return (
   <center> <div className="checkout-container">  <br/><br/> <br/><hr/>
      <h2>Checkout Details</h2>
      <div className="checkout-detail">
        <strong>Ship from:</strong> {shippingInfo.transportfrom}
      </div>
      <div className="checkout-detail">
        <strong>Ship to:</strong> {shippingInfo.transportto}
      </div>
      <div className="checkout-detail">
        <strong>Distance:</strong> <h2> {distance || 'Calculating...'} </h2>
      </div>
      <div className="checkout-detail">
        <strong>First avail. date:</strong> {shippingInfo.firstavaildate}
      </div>
      <div className="checkout-detail">
        <strong>Vehicle:</strong> {shippingInfo.vehicleyear} {shippingInfo.vehiclemake} {shippingInfo.vehiclemodel}
      </div>
      <div className="checkout-detail">
        <strong>Vehicle condition:</strong> {shippingInfo.vehiclecondition}
      </div>
      <div className="checkout-detail">
        <strong>Transport type:</strong> {shippingInfo.transporttype}
      </div>
      <div className="checkout-detail">
        <strong>Service type:</strong> 
      </div>
      <div className="checkout-detail">
        <strong>Insurance:</strong> Included
      </div>
      <div className="checkout-detail">
        <strong>Transit time:</strong> 4-6 days
      </div>
      <div className="checkout-detail">
        <strong>Due now:</strong> Don’t worry - you won’t pay until your pickup is scheduled.
      </div>
      <div className="checkout-detail">
        <strong>Price option:</strong> -- text
      </div>
    </div> <hr/> </center> 
  );
};

export default Checkout;
