import React from "react";

export const Footer = (props) => {
  return (
            <div id="footer">
                <div className="container text-center">
                <p>
                    &copy; 2024 Chimty. Design by{" "}
                    <a href="http://www.chimty.com" rel="nofollow">
                        Chimty
                    </a>
                </p>
                </div>
        </div>
  );
};
