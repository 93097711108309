import axios from 'axios';
import { toast } from 'react-toastify'; // Import toast
// Fetch vehicle information
export const fetchVehicleInfo = async (searchQuery) => {
    try {
        const response = await axios.get('https://api.shoppervista.com/api/Vehicleinfo/search', {
            params: { search: searchQuery }
        });
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error('There was an error fetching the vehicle info:', error);
        return [];
    }
};

// Submit vehicle information (create or update)
export const submitVehicleInfo = async (formData, editMode) => {
    try {
        if (editMode) {
            await axios.put(`https://api.shoppervista.com/api/Vehicleinfo/${formData.id}`, formData);
            toast.success('Vehicle info Updated successfully!');  
        } else {
            await axios.post('https://api.shoppervista.com/api/Vehicleinfo', formData);
            toast.success('Vehicle info saved successfully!');              
        }
    } catch (error) {
        console.error('There was an error submitting the form:', error);
    }
};

// Delete vehicle information
export const deleteVehicleInfo = async (id) => {
    try {
        if (!id) {
            console.error('Invalid ID:', id);
            return;
        }
        await axios.delete(`https://api.shoppervista.com/api/Vehicleinfo/${id}`);
    } catch (error) {
        console.error('There was an error deleting the vehicle:', error);
    }
};
