import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import '../VehicleInfoForm.css';   
import { fetchVehicleInfo, submitVehicleInfo, deleteVehicleInfo }  from '../actions/VehicleInfo.js'
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Import toast


// Add this section for search functionality
const SearchSection = ({ searchQuery, onSearchChange }) => (
    <div className="mb-4">
        <input
            type="text"
            className="form-control"
            placeholder="Search by make or model"
            value={searchQuery}
            onChange={e => onSearchChange(e.target.value)}
        />
    </div>
);

const VehicleInfoForm = () => {
    const [formData, setFormData] = useState({
        id: 0,
        vehiclemake: '',
        vehiclemodel: '',
        status: 1
    });
    const [vehicleList, setVehicleList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const vehiclesPerPage = 5;

    useEffect(() => {
        fetchVehicleInfo(searchQuery).then(setVehicleList);
    }, [searchQuery]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'id' ? Number(value) : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await submitVehicleInfo(formData, editMode);
            setEditMode(false);
            setFormData({ id: 0, vehiclemake: '', vehiclemodel: '', status: 1 });
            setShowModal(false); // Close modal after submission
            fetchVehicleInfo(searchQuery).then(setVehicleList);        
        } catch (error) {
            toast.error('There was an error submitting the vehicle info.');
        }
    };

    const handleEdit = (vehicle) => {
        setFormData(vehicle);
        setEditMode(true);
        setShowModal(true); // Open modal for editing
    };

    const handleDelete = async (id) => {
        try {
            await deleteVehicleInfo(id);
            fetchVehicleInfo(searchQuery).then(setVehicleList);
            toast.success('Vehicle info deleted successfully!');
        } catch (error) {
            toast.error('There was an error deleting the vehicle info.');
        }
    }; 

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const handleSearchChange = (query) => {
        setSearchQuery(query);
        setCurrentPage(0); // Reset to first page when searching
    };

    const handleShowModal = () => {
        setFormData({ id: 0, vehiclemake: '', vehiclemodel: '', status: 1 });
        setEditMode(false);
        setShowModal(true); // Open modal for new entry
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close modal without saving
    };

    const offset = currentPage * vehiclesPerPage;
    const currentVehicles = vehicleList.slice(offset, offset + vehiclesPerPage);
    const pageCount = Math.ceil(vehicleList.length / vehiclesPerPage);

    return (        
        <div className="container">           
        <br/><br/><br/><br/>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentVehicles.length > 0 ? (
                        currentVehicles.map((vehicle) => (
                            <tr key={vehicle.id}>
                                <td>{vehicle.id}</td>
                                <td>{vehicle.vehiclemake}</td>
                                <td>{vehicle.vehiclemodel}</td>
                                <td>{vehicle.status === 1 ? 'Active' : 'Inactive'}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(vehicle)}>Edit</button>
                                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(vehicle.id)}>Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No vehicles found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            
             
            <Button variant="primary"  onClick={handleShowModal}>
                Add New Vehicle
            </Button>
              
                <ReactPaginate 
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            
           

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editMode ? 'Edit Vehicle' : 'Add New Vehicle'}</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit} className="vehicle-form">
                                <div className="mb-3">
                                    <input type="hidden" id="id" name="id" value={formData.id} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="vehiclemake" className="form-label">Vehicle Make</label>
                                    <input type="text" className="form-control" id="vehiclemake" name="vehiclemake" value={formData.vehiclemake} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="vehiclemodel" className="form-label">Vehicle Model</label>
                                    <input type="text" className="form-control" id="vehiclemodel" name="vehiclemodel" value={formData.vehiclemodel} onChange={handleChange} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="status" className="form-label">Status</label>
                                    <select className="form-control" id="status" name="status" value={formData.status} onChange={handleChange} required>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">{editMode ? 'Update' : 'Submit'}</button>
                        </form>
                    </Modal.Body>
                </Modal>                
          </div> 
    );
};

export default VehicleInfoForm;
